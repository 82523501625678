



























import Vue from 'vue';
import Component from 'vue-class-component';
import { authSessionsModule } from '@/store';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import TableNavigation from '@/components/tables/navigation.vue';
import { AuthSessionsModule } from '@/store/sessions/sessions';
import AuthSessionsTabs from '@/components/sessions/sessions/auth.sessions.tabs.vue';

@Component({
  components: { AuthSessionsTabs, TableNavigation, PageLayout, TableGenerator }
})
export default class AuthSessionsList extends Vue {
  get state(): AuthSessionsModule {
    return authSessionsModule;
  }

  get schema(): ITableColumn[] {
    return [
      { label_i18n: 'uuid', prop: 'uuid', width: 200 },
      { label_i18n: 'login', prop: 'user__username', width: 120, sortable: 'custom' },
      { label_i18n: 'device_info', prop: 'device_info', 'min-width': 200 },
      { label_i18n: 'device', prop: 'mobile', width: 120, sortable: 'custom', formatter: this.mobileFormatter },
      { label_i18n: 'ip', prop: 'ip', width: 140 },
      { label_i18n: 'status', prop: 'status', width: 120, sortable: 'custom', formatter: this.statusFormatter },
      { label_i18n: 'last_ping_date', prop: 'last_ping_date', component: TableComponentNames.DateTime, width: 160, sortable: 'custom' }
    ];
  }

  get canBlock() {
    return this.$hasPermission('ffsecurity.add_deviceblacklistrecord');
  }

  get canDelete() {
    return this.$hasPermission('ffsecurity.delete_all_own_sessions') || this.$hasPermission('ffsecurity.delete_sessions');
  }

  created() {
    this.state.resetBeforeGet();
    this.$store.dispatch(this.$store.state.users.Action.Get);
  }

  mounted() {}

  rowClickHandler({ item }) {}

  createHandler() {
    this.$router.push(this.state.getNewItemRoute());
  }

  async blockHandler() {
    const uuids = this.state.selectedItems.map((v) => v.uuid);
    this.showBlockDialog({ uuid: uuids });
  }

  async deleteHandler() {
    for (let item of this.state.selectedItems) {
      try {
        await this.state.delete(item.id);
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.state.get();
  }

  actionHandler({ type, item }) {
    switch (type) {
      case 'block':
        this.showBlockDialog(item);
        break;
    }
  }

  showBlockDialog(item) {
    this.$store.state.dialog.blocklist_record.item = { uuid: item.uuid, reason: '', expire_date: null };
    this.$store.state.dialog.blocklist_record.enabled = true;
  }

  mobileFormatter({ mobile }) {
    return this.$tt(mobile ? 'mobile' : 'web');
  }

  statusFormatter({ status }) {
    const statusText = (status || 'none').toLowerCase();
    return this.$tt(`status_${statusText}`);
  }
}
